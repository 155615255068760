import { Box, Grid, Tooltip } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { Field, Input, Label } from 'components/Field';
import { t } from 'i18next';
import { ParamsFileManagement } from '../models/fileManagement';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DeleteBtn } from 'components/Common/buttonEffect/DeleteBtn';

export interface Props {
  filter: ParamsFileManagement;
  onChange?: (newFilter: ParamsFileManagement) => void;
  onSearchChange?: (newFilter: ParamsFileManagement) => void;
}

export default function FilterFileManagement({ filter, onChange, onSearchChange }: Props) {
  const searchRef = useRef<HTMLInputElement>();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onSearchChange) return;
    const newFilter: ParamsFileManagement = {
      ...filter,
      FileName: e.target.value.trim(),
    };
    onSearchChange(newFilter);
  };
  const handleClearFilter = () => {
    if (!onChange) return;

    const newFilter: ParamsFileManagement = {
      ...filter,
      PageIndex: 0,
      FileName: undefined,
    };
    onChange(newFilter);

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Field>
            {/* <Label>{t('Find')}</Label>
            <Input
              onChange={handleSearchChange}
              endAdornment={<GroupIconFilter reset={handleClearFilter} />}
              inputRef={searchRef}
            /> */}
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                boxShadow: ' rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
              }}
            >
              {' '}
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('Find')}
                inputRef={searchRef}
                onChange={handleSearchChange}
              />
              <SearchIcon sx={{ m: '10px' }} />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <Tooltip title={t('Clean').toString()}>
                  <RefreshIcon onClick={handleClearFilter} />
                </Tooltip>
              </IconButton>
            </Paper>
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
}
