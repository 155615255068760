import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse } from 'models';
import { FIGDS, ListParamsFIGDS } from './models/FIGDS';


export interface FIGDSModel {
  isLoading: Boolean;
  items?: FIGDS[];
  error?: string;
  filterDM: ListParamsFIGDS;
  filterFIGDSStatus: ListParamsFIGDS;
  filter: ListParams;
  totalPages: number;
  totalRows: number;
}
const initialState: FIGDSModel = {
  isLoading: false,
  items: [],
  error: '',
  filter: {
    PageIndex: 0,
    PageSize: 10,
  },
  filterFIGDSStatus: {
    FileManagementId: undefined,
    FileDataStatus: undefined,
    PageIndex: 0,
    PageSize: 10,
  },
  filterDM: {
    FileManagementId: undefined,
    FileDataStatus: undefined,
    PageIndex: 0,
    PageSize: 10,
  },
  totalPages: 0,
  totalRows: 0,
};

const FIGDSSlice = createSlice({
  name: 'File Risk Group Data Source',
  initialState,
  reducers: {
    // get data
    getItems(state, action: PayloadAction<ListParamsFIGDS>) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action: PayloadAction<ListResponse<FIGDS>>) {
      state.items = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.totalRows = action.payload.totalRows;
      state.isLoading = false;
    },
    getItemsFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },
    setFilter(state, action: PayloadAction<ListParams>) {
      state.filter = {
        ...action.payload,
        PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
      };
    },
    setFilterFIGDS(state, action: PayloadAction<ListParamsFIGDS>) {
      state.filterDM = {
        ...action.payload,
        // PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        FileManagementId: action.payload.FileManagementId,
        FileDataStatus: action.payload.FileDataStatus,
        PageSize:action.payload.PageSize,
        PageIndex: action.payload.PageIndex,
      };
    },
    setFilterDMStatus(state, action: PayloadAction<ListParamsFIGDS>) {
      state.filterFIGDSStatus = {
        ...action.payload,
        // PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        FileManagementId: action.payload.FileManagementId,
        FileDataStatus: action.payload.FileDataStatus,
        PageSize: 10,
        PageIndex: 0,
      };
    },
  },
});

export const FIGDSActions = FIGDSSlice.actions;
export const selectFIGDS = (state: RootState) => state.figds.items;
export const selectFIGDSLoading = (state: RootState) => state.figds.isLoading;
export const selectFIGDSFilter = (state: RootState) => state.figds.filter;
export const selectFIGDSFilterDM = (state: RootState) => state.figds.filterDM;
export const selectFIGDSTotalRows = (state: RootState) => state.figds.totalRows;

const FIGDSReducer = FIGDSSlice.reducer;
export default FIGDSReducer;
