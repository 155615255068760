import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, IconButton, List, ListItemIcon, styled, Toolbar } from '@mui/material';
import { ListItemButton, ListItemText } from '@mui/material';
import TopicIcon from '@mui/icons-material/Topic';
import Typography from '@mui/material/Typography';
import { NavLink, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { makeStyles } from '@mui/styles';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

const Link = styled(NavLink)({
  textDecoration: 'none',
  color: 'inherit',

  '&.active > div': {
    backgroundColor: 'whitesmoke',
  },
});

const useStyles = makeStyles({
  icon: {
    color: '#3C8DBC',
  },
});

export const MenuDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const user = useAppSelector((s) => s.auth.currentUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer open={openDrawer} onClose={toggleDrawer}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            minWidth: '360px',
          }}
        >
          {openDrawer && (
            <Typography
              sx={{
                marginRight: '54px',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '20px',
                width: '70%',
                color: '#3498db',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <HomeIcon
                onClick={() => {
                  history.push('/home');
                  toggleDrawer();
                }}
                sx={{ cursor: 'pointer' }}
              />
              {t('Data reconciliation')}
            </Typography>
          )}
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <Link to="/doh">
            <ListItemButton onClick={toggleDrawer}>
              <ListItemIcon>
                <LocalHospitalIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('DoH')} />
            </ListItemButton>
          </Link>
          <Link to="/FileImmunizationGroupDataSource">
            <ListItemButton onClick={toggleDrawer}>
              <ListItemIcon>
                <TopicIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('File Immunization GroupData Source')} />
            </ListItemButton>
          </Link>
          <Link to="/file-management">
            <ListItemButton onClick={toggleDrawer}>
              <ListItemIcon>
                <TopicIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('File management')} />
            </ListItemButton>
          </Link>
          <Link to="/data-management">
            <ListItemButton onClick={toggleDrawer}>
              <ListItemIcon>
                <AssignmentLateIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('Data management')} />
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
    </>
  );
};
