import { ListParams, ListResponse, ResponseMessage } from 'models';
import { apiLinks } from 'utils';
import axiosClient from '../../../api/axiosClient';
import {
  FileManagement,
  FileManagementGetById,
  ParamsFileManagement,
} from '../models/fileManagement';

export const fileManagementApi = {
  get(params: ParamsFileManagement): Promise<ListResponse<FileManagement>> {
    return axiosClient.get(apiLinks.dataReconciliation.fileManagement, { params });
  },
  getId(id: string): Promise<FileManagementGetById> {
    return axiosClient.get(`${apiLinks.dataReconciliation.fileManagement}/${id}`);
  },
  import(files: File): Promise<ResponseMessage<any>> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const data = new FormData();
    data.append('file', files);
    return axiosClient.post(apiLinks.dataReconciliation.import, data);
  },
};
