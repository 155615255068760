import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse } from 'models';
import { FileManagement, FileManagementGetById, ParamsFileManagement } from './models/fileManagement';

export interface FileManagementModel {
  isLoading: Boolean;
  items?: FileManagement[];
  getId?: FileManagementGetById;
  import?: FileManagement;
  error?: string;
  filter: ParamsFileManagement;
  totalPages: number;
  totalRows: number;
}
const initialState: FileManagementModel = {
  isLoading: false,
  items: [],
  getId: undefined,
  import: undefined,
  error: '',
  filter: {
    // FromDate: '',
    // ToDate: '',
    FileName: '',
    // FileDataStatus: 0,
    // IsDelete: false,
    PageIndex: 0,
    PageSize: 10,
  },
  totalPages: 0,
  totalRows: 0,
};

const fileManagementSlice = createSlice({
  name: 'file management',
  initialState,
  reducers: {
    // get data
    getItems(state, action: PayloadAction<ListParams>) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action: PayloadAction<ListResponse<FileManagement>>) {
      state.items = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.totalRows = action.payload.totalRows;
      state.isLoading = false;
    },
    getItemsFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },
    // get id
    get(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    getSuccess(state, action: PayloadAction<FileManagementGetById>) {
      state.isLoading = false;
      state.getId = action.payload;
    },
    getFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    // import
    createFileManagement(state, action: PayloadAction<FileManagement>) {
      state.isLoading = true;
    },
    createFileManagementSuccess(state, action: PayloadAction<FileManagement>) {
      state.import = action.payload;
      state.isLoading = false;
    },
    createFileManagementFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },

    setFilter(state, action: PayloadAction<ParamsFileManagement>) {
      state.filter = {
        ...action.payload,
        // PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        FileName: action.payload.FileName,
        PageIndex: action.payload.PageIndex,

      };
    },
  },
});

export const fileManagementActions = fileManagementSlice.actions;

export const selectFileManagement = (state: RootState) => state.fileManagement.items;
export const selectFileManagementGetId = (state: RootState) => state.fileManagement.getId;
export const selectFileManagementCreate = (state: RootState) => state.fileManagement.import;

export const selectFileManagementLoading = (state: RootState) => state.fileManagement.isLoading;
export const selectFileManagementTotalPages = (state: RootState) => state.fileManagement.totalPages;
export const selectFileManagementTotalRows = (state: RootState) => state.fileManagement.totalRows;
export const selecFileManagementFilter = (state: RootState) => state.fileManagement.filter;

const fileManagementReducer = fileManagementSlice.reducer;
export default fileManagementReducer;
