import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse } from 'models';
import { Ward } from '../models/doh';

export interface LocalModel {
  isLoading: Boolean;
  province?: any;
  district?: any;
  ward?: any;
  quarter?: any;
  SubQuarter?: any;
  error?: string;
}
const initialState: LocalModel = {
  isLoading: false,
  province: undefined,
  district: undefined,
  ward: undefined,
  quarter: undefined,
  SubQuarter: undefined,
  error: '',
};

const localSlice = createSlice({
  name: 'file management',
  initialState,
  reducers: {
    // get data
    getDistrict(state) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action: PayloadAction<Ward>) {
      state.ward = action.payload;
      state.isLoading = false;
    },
    getItemsFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },
  },
});

export const localActions = localSlice.actions;

export const selectLocal = (state: RootState) => state.local.ward;

const localReducer = localSlice.reducer;
export default localReducer;
