import { Pagination, TablePagination } from '@mui/material';

export default function Paginations({
  onRowsPerPageChange,
  totalCount,
  count,
  page,
  PageIndexs,
  onChange,
  onPageChange,
  rowsPerPage,
}: any) {
  return (
    <>
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        count={count}
        page={page}
        onChange={onChange}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={PageIndexs}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
}
