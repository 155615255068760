import { PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { ListParams, ListResponse, PaginationParams } from 'models';
import { toast } from 'react-toastify';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { fileManagementApi } from './api/fileManagementAPI';
import { fileManagementActions } from './fileManagementSlice';
import {
  FileManagement,
  FileManagementGetById,
  ParamsFileManagement,
} from './models/fileManagement';

// get list
function* fetchItemsFileManagement(action: PayloadAction<ParamsFileManagement>) {
  try {
    const res: ListResponse<FileManagement> = yield call(fileManagementApi.get, action.payload);
    yield put(fileManagementActions.getItemsSuccess(res));
  } catch (error: any) {
    console.log(error);
    yield put(fileManagementActions.getItemsFailed());
  }
}
function* getItems() {
  yield takeEvery(fileManagementActions.getItems, fetchItemsFileManagement);
}
// get id
function* handleGetIdFileManagement(action: PayloadAction<string>) {
  try {
    const rs1: FileManagementGetById = yield call(fileManagementApi.getId, action.payload);
    yield put(fileManagementActions.getSuccess(rs1));
  } catch (error) {}
}
// import
// function* importFileManagementApi(action: PayloadAction<FileManagement>) {
//   try {
//     const res: FileManagement = yield call(fileManagementApi.import, action.payload);
//     yield put(fileManagementActions.createFileManagementSuccess(res));
//     toast.success(t('Create success'));
//     var filter = {
//       FileName: '',
//       FromDate: '',
//       ToDate: '',
//       FileDataStatus: 0,
//       IsDelete: false,
//       PageIndex: 1,
//       PageSize: 10,
//     } as ParamsFileManagement;
//     yield put(fileManagementActions.getItems(filter));
//   } catch (error: any) {
//     console.log(error);
//     yield put(fileManagementActions.createFileManagementFailed());
//     toast.error(t('Create fail'));
//     // eslint-disable-next-line @typescript-eslint/no-redeclare
//     var filter = {
//       FileName: '',
//       FromDate: '',
//       ToDate: '',
//       FileDataStatus: 0,
//       IsDelete: false,
//       PageIndex: 1,
//       PageSize: 10,
//     } as ParamsFileManagement;
//     yield put(fileManagementActions.getItems(filter));
//   }
// }
// function* importFileManagement() {
//   yield takeLatest(fileManagementActions.createFileManagement, importFileManagementApi);
// }

// khi có sự kiện thì gọi
export function* fileManagementSaga() {
  yield all([fork(getItems)]);
  yield takeLatest(fileManagementActions.get, handleGetIdFileManagement);
}
