import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Header } from 'components/Common/header';
import { authActions } from 'features/auth/authSlice';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import DashboardFeature from 'features/dashboard';
import FileManagementFeature from 'features/fileManagement';
import DataManagementFeature from 'features/dataManagement';
import NotFound from './page404';
import DoHFeature from 'features/doh';
import FIGDSFeature from 'features/FileImmunizationGroupDataSource';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

export function AppLayout() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const backgroundColor = useAppSelector((state) => state.layout.backgroundColor);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useAppSelector((s) => s.auth.currentUser);
  const access_token =
    localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token');
  useEffect(() => {
    dispatch(authActions.getUserInfo());
  }, [dispatch]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: backgroundColor,
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Header />
        <Switch>
          <Route path="/doh">
            <DoHFeature />
          </Route>
          <Route path="/FileImmunizationGroupDataSource">
            <FIGDSFeature />
          </Route>
          <Route path="/file-management">
            <FileManagementFeature />
          </Route>{' '}
          <Route path="/data-management">
            <DataManagementFeature />
          </Route>
          <Route path="/home">
            <DashboardFeature />
          </Route>
          <Route path="*/*" render={() => <NotFound />} />
        </Switch>
      </Box>
    </Box>
  );
}
