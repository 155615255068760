import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { viVN } from '@mui/material/locale';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Breadcrumb from 'components/Common/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { FileManagementGetById } from '../models/fileManagement';
import { fileManagementActions, selectFileManagementLoading } from '../fileManagementSlice';
import Paper from '@mui/material/Paper';

const theme = createTheme({}, viVN);

export interface Props {
  initialValue?: FileManagementGetById;
}

const TextColor = '#3c8dbc!important';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    header: {
      background: '#ECF3FB',
      borderBottom: '2px solid  #3c8dbc!important',
    },
    body: {
      padding: '20px',
    },
    table: {
      border: '2px solid #3c8dbc',
      boxShadow: 'none!important',
    },
    textHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: TextColor,
    },
    titleContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },

    loading: {
      position: 'absolute',
      top: -8,
      width: '100%',
    },
    filter: {
      width: '100%',
      display: 'flex',
      justifyItems: 'space-between',
      alignItems: 'center',
    },
    bc: {
      marginLeft: '20px',
    },
    link: {
      textDecoration: 'none',
      color:"#000"
    },
    rowLink: {
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const ListPageGetIDFM = ({ initialValue }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(fileManagementActions.get(id));
    }
  }, [dispatch, id]);

  const items = [
    {
      href: '/file-management',
      content: t('File management'),
    },
  ];
  const loading = useAppSelector(selectFileManagementLoading);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.bc}>
        <Breadcrumb items={items} text={t('Detail')} />
      </div>
      <div className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typography component="h1" variant="h5" fontWeight="bold">
            {t('File management')} - {initialValue?.data.fileName}
          </Typography>
        </Box>
        {/* Table */}
        {loading && <LinearProgress />}
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small" aria-label="simple table">
            <TableHead className={classes.header}>
              <TableRow>
                <TableCell className={classes.textHeader}>{t('File')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.body}>
              <TableRow key={initialValue?.data?.id}>
                <TableCell className={classes.rowLink}>
                  <a href={initialValue?.data.url} download className={classes.link}>
                    {t('Click download')}
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};
