import { apiLinks } from 'utils';
import axiosClient from '../../../api/axiosClient';
import {
  DataManagement,
  ListResponseDataManagement,
  ParamsDataManagement,
} from '../models/dataManagement';

export const dataManagementApi = {
  get(params: ParamsDataManagement): Promise<ListResponseDataManagement<DataManagement>> {
    return axiosClient.get(apiLinks.dataReconciliation.fileRiskGroupDataSource, { params });
  },
};
