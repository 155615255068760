import { PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { ListResponse } from 'models';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DoHApi } from './api/dohAPI';
import { DoHActions } from './dohSlice';
import { DoH, DoHParams } from './models/doh';

// get list
function* fetchItemsDoH(action: PayloadAction<DoHParams>) {
  try {
    const res: ListResponse<DoH> = yield call(DoHApi.get, action.payload);
    yield put(DoHActions.getItemsSuccess(res));
  } catch (error: any) {
    console.log(error);
    yield put(DoHActions.getItemsFailed());
  }
}
function* getItems() {
  yield takeEvery(DoHActions.getItems, fetchItemsDoH);
}

// khi có sự kiện thì gọi
export function* DoHSaga() {
  yield all([fork(getItems)]);
}
