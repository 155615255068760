import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer from 'features/auth/authSlice';
import layoutReducer from 'features/layout/layoutSlice';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { history } from 'utils';
import rootSaga from './rootSaga';
import fileManagementReducer from 'features/fileManagement/fileManagementSlice';
import dataManagementReducer from 'features/dataManagement/dataManagementSlice';
import DoHReducer from 'features/doh/dohSlice';
import localReducer from 'features/doh/action/locationSlice';
import FIGDSReducer from 'features/FileImmunizationGroupDataSource/FIGDS_Slice';

const rootReducer = combineReducers({
  router: connectRouter(history),
  layout: layoutReducer,
  auth: authReducer,
  fileManagement: fileManagementReducer,
  dataManagement: dataManagementReducer,
  doh: DoHReducer,
  local: localReducer,
  figds: FIGDSReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
