import { ChangePasswordPayload } from 'features/auth/components/ChangePasswordForm';
import { apiLinks } from 'utils';
import axiosClient from '../../../api/axiosClient';
import { LoginPayload, User } from '../models';

export const authApi = {
  login(payload: LoginPayload): Promise<User> {
    return axiosClient.post(apiLinks.auth.loginNew, payload);
  },
  getUserInfo(): Promise<User> {
    return axiosClient.get(apiLinks.auth.getUserInfo);
  },
  changePassword(payload: ChangePasswordPayload): Promise<any> {
    return axiosClient.put(apiLinks.auth.changePassword, payload);
  },
};
