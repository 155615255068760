import * as React from 'react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { viVN } from '@mui/material/locale';
import { Box, Grid, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useEffect, useState } from 'react';
import Popup from 'components/Common/Popup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Paginations from 'components/Common/Pagination';
import { DataManagement, ParamsDataManagement } from '../models/dataManagement';
import {
  dataManagementActions,
  selectDataManagement,
  selectDataManagementFilter,
  selectDataManagementFilterDM,
  selectDataManagementLoading,
  selectDataManagementTotalRows,
} from '../dataManagementSlice';
import PaginationsOfDataManagement from '../components/PaginationOfDataManagement';
import FilterOfDataManagement from '../components/FilterOfDataManagement';
import DataManagementTable from '../components/dataManagementTable';

const theme = createTheme({}, viVN);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    titleContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',

      marginBottom: 16,
    },

    loading: {
      position: 'absolute',
      top: -8,
      width: '100%',
    },
    filter: {
      width: '100%',
      display: 'flex',
      justifyItems: 'space-between',
      alignItems: 'center',
    },
  })
);

export const ListPage = () => {
  const [dataManagement, setDataManagement] = useState<DataManagement>();
  const [getDataManagement, setGetDataManagement] = useState<DataManagement[]>();
  //   const initialValues: DataManagement = {
  //     ...fileManagement,
  //   } as DataManagement;

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPut, setOpenPopupPut] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const loading = useAppSelector(selectDataManagementLoading);

  const data = useAppSelector(selectDataManagement);
  const filter = useAppSelector(selectDataManagementFilter);
  const filterDM = useAppSelector(selectDataManagementFilterDM);

  const totalRows = useAppSelector(selectDataManagementTotalRows);

  // React.useCallback(() => {
  //   dispatch(techSkillActions.getItems(filter));
  // }, [dispatch, filter]);

  useEffect(() => {
    dispatch(dataManagementActions.getItems(filterDM));
  }, [dispatch, filterDM]);

  useEffect(() => {
    setGetDataManagement(getDataManagement);
  }, [getDataManagement]);

  //pagination
  const handlePageChange = (e: any, page: number) => {
    setPageIndex(page);
    dispatch(
      dataManagementActions.setFilterDM({
        ...filterDM,
        PageIndex: page,
      })
    );
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      dataManagementActions.setFilterDM({
        ...filterDM,
        PageIndex: 0,
        PageSize: parseInt(e.target.value, 10),
      })
    );
  };
  // ----------------------------------------

  // erorr
  const handle = () => {
    console.log('ok');
    setOpenPopup(true);
  };

  //filter
  const handleSearchChange = (newFilter: ParamsDataManagement) => {
    dispatch(dataManagementActions.setFilterDM(newFilter));
  };
  const handleFilterChange = (newFilter: ParamsDataManagement) => {
    dispatch(dataManagementActions.setFilterDM(newFilter));
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typography component="h1" variant="h5" fontWeight="bold">
            {t('Risk Group Management')}
          </Typography>
        </Box>
        {/* filter */}
        <Grid xs={6} width="50%" md={6} sx={{ marginBottom: '20px' }}>
          <FilterOfDataManagement
            filter={filterDM}
            onSearchChange={handleSearchChange}
            onChange={handleFilterChange}
          />
        </Grid>{' '}
        {/* Table */}
        {loading && <LinearProgress />}
        <DataManagementTable data={data || []} onErr={handle} />
        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <Paginations
            count={Math.ceil(totalRows / filter.PageSize)}
            page={filter.PageIndex}
            rowsPerPage={filterDM.PageSize}
            PageIndexs={filterDM.PageIndex}
            totalCount={totalRows}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onChange={(e: any, page: number) => {
              handlePageChange(e, page - 1);
            }}
            onPageChange={(e: any, page: number) => {
              handlePageChange(e, page);
            }}
          />
        </Box>
      </div>
      <Popup
        title={`${t('Error')}`}
        openPopup={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setDataManagement(undefined);
        }}
      >
        form bug
      </Popup>
    </ThemeProvider>
  );
};
