import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fileManagementActions, selectFileManagementGetId } from '../fileManagementSlice';
import { ListPageGetIDFM } from './GetIdPage';

type Props = {};

export default function FMGetIdPage({}: Props) {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const getId = useAppSelector(selectFileManagementGetId);
  console.log("🚀 ~ file: FileManagementGetByIdPage.tsx:13 ~ FMGetIdPage ~ getId:", getId)

  useEffect(() => {
    dispatch(fileManagementActions.get(id));
  }, [dispatch, id]);
  return (
    //     <div>
    //       <ListPageAddMember initialValue={getId} />
    //     </div>
    <>
      <ListPageGetIDFM initialValue={getId} />
    </>
  );
}
