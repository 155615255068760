import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface LayoutState {
  backgroundColor: string;
}

const initialState: LayoutState = {
  backgroundColor: '#ECF3FB',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
});

export const layoutActions = layoutSlice.actions;

const layoutReducer = layoutSlice.reducer;
export default layoutReducer;
