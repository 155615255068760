import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';

export default function DashboardFeature() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact>
        <DashboardPage />
      </Route>
    </Switch>
  );
}
