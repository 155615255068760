import { Box, Grid, Input, Tooltip } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { Field } from 'components/Field';
import { t } from 'i18next';
import { ParamsDataManagement } from '../models/dataManagement';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { InputField } from 'components/FormFields';
import { SelectField } from 'components/FormFields';

import { useForm } from 'react-hook-form';
import { SelectOption } from '../../../components/FormFields/SelectField';
import { useAppDispatch } from 'app/hooks';
import { dataManagementActions } from '../dataManagementSlice';
import { Label } from '@mui/icons-material';
import { log } from 'console';

export interface Props {
  filter: ParamsDataManagement;
  onChange?: (newFilter: ParamsDataManagement) => void;
  onSearchChange?: (newFilter: ParamsDataManagement) => void;
}

export default function FilterOfDataManagement({ filter, onChange, onSearchChange }: Props) {
  const searchRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onSearchChange) return;
    const newFilter: ParamsDataManagement = {
      ...filter,
      FileDataStatus: e.target.valueAsNumber,
    };
    onSearchChange(newFilter);
  };
  const handleClearFilter = () => {
    if (!onChange) return;

    const newFilter: ParamsDataManagement = {
      ...filter,
      PageIndex: 0,
      FileDataStatus: undefined,
    };
    onChange(newFilter);

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ParamsDataManagement>({});
  //
  const handleFormSubmit = async (formValues: ParamsDataManagement) => {
    let filters = {
      ...filter,
      FileDataStatus: formValues.FileDataStatus,
    } as ParamsDataManagement;
    console.log(
      '🚀 ~ file: FilterOfDataManagement.tsx:61 ~ handleFormSubmit ~ formValues:',
      filters
    );
    dispatch(dataManagementActions.setFilterDM(filters));
  };
  const list = [
    {
      label: t('All'),
      value: '',
    },
    {
      label: t('Processing'),
      value: '2',
    },
    {
      label: t('Invalid'),
      value: '1',
    },
    {
      label: t('Valid'),
      value: '0',
    },
  ] as SelectOption[];
  return (
    <Box>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                boxShadow: ' rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
              }}
            >
              {' '}
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('Find')}
                inputRef={searchRef}
                onChange={handleSearchChange}
              />
              <SearchIcon sx={{ mr: '10px' }} />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Tooltip title={t('Clean').toString()}>
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <RefreshIcon onClick={handleClearFilter} />
                </IconButton>
              </Tooltip>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper
              component="form"
              sx={{
                ml: '5px',
                p: '2px 4px',
                display: 'flex',
                height: 50,
                alignItems: 'center',
                width: 200,
                boxShadow: ' rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
              }}
            >
              <SelectField
                control={control}
                name={'FileDataStatus'}
                label={t('Status')}
                options={list}
              />
            </Paper>
          </Grid>
          <Grid
            md={3}
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth:"15%!important"
            }}
          >
            
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: '10px' }}
            >
              {t('Find')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
