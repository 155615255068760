import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import { DoH } from '../models/doh';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

export interface DoHTableProps {
  data: DoH[];
  onDetail?: (props: DoH) => void;
}

const TextColor = '#3c8dbc!important';
const widthSpace = '200px!important';
const useStyles = makeStyles({
  action: { color: TextColor, paddingRight: '30px!important' },
  actionButon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    border: '2px solid #3c8dbc',
    boxShadow: 'none!important',
  },
  header: {
    background: '#ECF3FB',
    borderBottom: '2px solid  #3c8dbc!important',
  },
  textHeader: {
    color: TextColor,
  },
  status_pending: {
    backgroundColor: '#F0AD4E',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_fail: {
    backgroundColor: '#D9534F',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_success: {
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#5CB85C',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
  },
  edit: {
    fontSize: '1.2rem!important',
  },
  delete: {
    fontSize: '1.2rem!important',
  },
  Nodata: {
    color: '#DAE0E5',
  },
  textHeaderAddress: {
    textAlign: 'center',
    color: '#fff',
  },
});

export default function DoHTable({ data, onDetail }: DoHTableProps) {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.textHeader}>{t('Fullname')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((e, index) => (
                <TableRow>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        {' '}
                        {!e?.fullName ? (
                          <span className={classes.Nodata}>{t('No data')}</span>
                        ) : (
                          e?.fullName
                        )}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead className={classes.header}>
                          <TableRow>
                            <TableCell className={classes.textHeader}>{t('BOD')}</TableCell>
                            <TableCell className={classes.textHeader}>{t('Gender')}</TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('Identity card')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>{t('Address')}</TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('vaccineStatus')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('firstShotType')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('secondShotType')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('thirdShotType')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('Have been infected')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('infectedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('underlyingDiseases')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('firstExaminatedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('firstExaminatedResult')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('secondExaminatedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('secondExaminatedResult')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('thirdExaminatedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('thirdExaminatedResult')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('fourthExaminatedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('fourthExaminatedResult')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('fifthExaminatedDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('fifthExaminatedResult')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('additionalShot')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('additionalShotType')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('additionalShotDate')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('wasGivenCDrugs')}
                            </TableCell>
                            <TableCell className={classes.textHeader}>
                              {t('wasIsolatedAtHome')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.dob ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.dob
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.gender ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.gender
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.cccd ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.cccd
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: '500px' }}
                        >{`${e?.houseNumber}, phường ${e.ward}, quận ${e.district}, ${e.city}`}</TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.vaccineStatus ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.vaccineStatus
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.firstShotType ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.firstShotType
                          )}
                        </TableCell>{' '}
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.secondShotType ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.secondShotType
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.thirdShotType ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.thirdShotType
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.haveBeenInfected ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.haveBeenInfected
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.infectedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.infectedDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.underlyingDiseases ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.underlyingDiseases
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.firstExaminatedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            moment(e?.firstExaminatedDate).format('DD/MM/YYYY')
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.firstExaminatedResult ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.firstExaminatedResult
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.secondExaminatedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.secondExaminatedDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.secondExaminatedResult ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.secondExaminatedResult
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.thirdExaminatedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.thirdExaminatedDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.thirdExaminatedResult ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.thirdExaminatedResult
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.fourthExaminatedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.fourthExaminatedDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.fourthExaminatedResult ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.fourthExaminatedResult
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.fifthExaminatedDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.fifthExaminatedDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.fifthExaminatedResult ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.fifthExaminatedResult
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.additionalShot ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.additionalShot
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.additionalShotType ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.additionalShotType
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.additionalShotDate ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.additionalShotDate
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.wasGivenCDrugs ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.wasGivenCDrugs
                          )}
                        </TableCell>
                        <TableCell sx={{ minWidth: widthSpace }}>
                          {!e?.wasIsolatedAtHome ? (
                            <span className={classes.Nodata}>{t('No data')}</span>
                          ) : (
                            e?.wasIsolatedAtHome
                          )}
                        </TableCell>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={16}>
                  {t('No data to display')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
