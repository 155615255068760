import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import { t } from 'i18next';

const NotFound = () => (
  <div
    className="not-found"
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <img
      src="https://www.pngitem.com/pimgs/m/561-5616833_image-not-found-png-not-found-404-png.png"
      alt="not-found"
      style={{ maxWidth: '50rem', minHeight: '30rem', mixBlendMode: 'darken' }}
    />
    <Link to="/home" className="link-home" style={{ textDecoration:"none"}}>
      <Button variant="contained" startIcon={<HomeIcon />}>
        {t('Home')}
      </Button>
    </Link>
  </div>
);

export default NotFound;
