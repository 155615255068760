import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParamsDM } from 'features/dataManagement/models/dataManagement';
import { ListParams, ListResponse } from 'models';
import { DoH, DoHParams } from './models/doh';

export interface DataManagementModel {
  isLoading: Boolean;
  items?: DoH[];
  error?: string;
  filterDoH: DoHParams;
  filter: ListParams;
  totalPages: number;
  totalRows: number;
}
const initialState: DataManagementModel = {
  isLoading: false,
  items: [],
  error: '',
  filter: {
    PageIndex: 0,
    PageSize: 10,
  },
  filterDoH: {
    CCCD: undefined,
    IsChecked: undefined,
    District: undefined,
    PageIndex: 0,
    PageSize: 10,
  },
  totalPages: 0,
  totalRows: 0,
};

const DoHSlice = createSlice({
  name: 'doh',
  initialState,
  reducers: {
    // get data
    getItems(state, action: PayloadAction<DoHParams>) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action: PayloadAction<ListResponse<DoH>>) {
      state.items = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.totalRows = action.payload.totalRows;
      state.isLoading = false;
    },
    getItemsFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },
    setFilter(state, action: PayloadAction<ListParamsDM>) {
      state.filter = {
        ...action.payload,
        PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
      };
    },
    setFilterDM(state, action: PayloadAction<DoHParams>) {
      state.filterDoH = {
        ...action.payload,
        PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        // PageIndex: action.payload.PageIndex,
      };
    },
  },
});

export const DoHActions = DoHSlice.actions;
export const selectDoH = (state: RootState) => state.doh.items;
export const selectDoHLoading = (state: RootState) => state.doh.isLoading;
export const selectDoHFilter = (state: RootState) => state.doh.filter;
export const selectDoHFilterDOH = (state: RootState) => state.doh.filterDoH;
export const selectDoHTotalRows = (state: RootState) => state.doh.totalRows;

const DoHReducer = DoHSlice.reducer;
export default DoHReducer;
