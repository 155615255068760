const user_api = `http://202.78.227.175:30854/api`;
const user_api_new = `https://tcm-api.hcdc.vn/api/v1`;

// const base_url = `https://localhost:5001/api`;
const url_data_reconciliation = `http://192.168.35.25:32499/v1`;
export const apiLinks = {
  auth: {
    login: `${user_api}/Users/Login`,
    loginNew: `${user_api_new}/auth/Users/Login`,
    getUserInfo: `${user_api}/Users/GetUserInfo`,
    changePassword: `${user_api}/Users/ChangePassword`,
  },
  dataReconciliation: {
    fileManagement: `${url_data_reconciliation}/FileManagement`,
    fileRiskGroupDataSource: `${url_data_reconciliation}/FileRiskGroupDataSource`,
    doh: `${url_data_reconciliation}/DoH`,
    import: `${url_data_reconciliation}/FileManagement/Import`,
    fileImmunizationGroupDataSource:  `${url_data_reconciliation}/FileImmunizationGroupDataSource`,
  },
};
