import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, CircularProgress, Divider, LinearProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { InputField } from 'components/FormFields';
import { fileManagementApi } from 'features/fileManagement/api/fileManagementAPI';
import { FileManagement } from 'features/fileManagement/models/fileManagement';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// import excelTemplate from '../../../app/NNC_Test2.xlsx';
export interface TestingFormProps {
  initialValues?: any;
  onClose: () => void;
}
const schema = yup.object().shape({
  file: yup.string().required(t('Import file is required')),
});
export default function TestingForm({ initialValues, onClose }: TestingFormProps): JSX.Element {
  const [error, setError] = useState<string>('');
  const [fileUpdate, setFileUpdate] = useState<File>();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  // const filter = useAppSelector(selectTestingFilter);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const onChangeFiles = (e: any) => {
    console.log(e.target.files[0]);
    console.log('___');
    setFileUpdate(e.target.files[0]);
    console.log(fileUpdate);
  };
  const handleTestingFormSubmit = (formValues: any) => {
    if (fileUpdate == undefined) {
      return;
    }
    setLoading(true);
    var form = new FormData();
    form.append('file', fileUpdate, fileUpdate.type);
    // fileManagementApi.import(form).then((res) => {
    //   setLoading(false);
    //   onClose();
    // });
  };
  const downloadFile = (file: any) => {
    const link = document.createElement('a');
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box maxWidth={400}>
      <form onSubmit={handleSubmit(handleTestingFormSubmit)}>
        <InputField
          name="file"
          type={'file'}
          onChange={(e: any) => {
            onChangeFiles(e);
          }}
          control={control}
          placeholder={t('Select import file')}
        />
        <Divider sx={{ marginTop: '20px' }}>{t('Download file template')}</Divider>
        <Button variant="outlined" startIcon={<DocumentScannerIcon />}>
          <a href="/app/NNC_Test2.xlsx" download style={{ textDecoration: 'none' }}>
            {t('Use sample files to ensure accurate data')}
          </a>
        </Button>

        {/* {downloadFile(excelTemplate)} */}
        {error && <Alert severity="error">{error}</Alert>}
        {loading == true && (
          <LinearProgress
            color={'primary'}
            value={100}
            style={{
              position: 'absolute',
              top: -8,
              width: '100%',
            }}
          />
        )}
        <LinearProgress
          color={'primary'}
          value={100}
          style={{
            position: 'absolute',
            top: -8,
            width: '100%',
          }}
        />
        <Box mt={3}>
          <Button type="submit" variant="contained" color="primary">
            {isSubmitting && <CircularProgress size={16} color="primary" />}
            &nbsp;{t('Confirm')}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
