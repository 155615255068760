import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/hooks';
import { history } from 'utils';
import TopicIcon from '@mui/icons-material/Topic';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
type Props = {};

const useStyles = makeStyles({
  container: {
    padding: '0 20px 0 20px',
  },
  text: {
    fontFamily: 'Helvetica',
  },
});
export const DashboardPage = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={classes.container}>
        <div>
          {/* <Breadcrumb items={[]} text={t("Dashboard")} /> */}
          <Typography
            className={classes.text}
            component="h1"
            variant="h4"
            fontWeight="bold"
            fontFamily="Anton"
          >
            {t('Dashboard')}
          </Typography>
        </div>
        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                startIcon={<LocalHospitalIcon />}
                onClick={() => history.push('/doh')}
                sx={{ cursor: 'pointer', width: '100%', height: '65px' }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '50px',
                    alignContent: 'center',
                  }}
                >
                  {t('DoH')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                startIcon={<TopicIcon />}
                onClick={() => history.push('/file-management')}
                sx={{ cursor: 'pointer', width: '100%', height: '65px' }}
              >
                <Typography sx={{ display: 'flex', justifyContent: 'center', width: '150px' }}>
                  {t('File management')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                startIcon={<AssignmentLateIcon />}
                onClick={() => history.push('/data-management')}
                sx={{ cursor: 'pointer', width: '100%', height: '65px' }}
              >
                <Typography sx={{ display: 'flex', justifyContent: 'center', width: '250px' }}>
                  {t('Data management')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
