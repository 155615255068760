import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { FileManagement } from '../models/fileManagement';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { red } from '@mui/material/colors';
import StorageIcon from '@mui/icons-material/Storage';
import CommonDialog from 'components/Common/CommonDialog';
import FormBug from './FormBug';
export interface FileManagementTableProps {
  data: FileManagement[];
  onErr?: (fileManagement: FileManagement) => void;
  onViewData?: (fileManagement: FileManagement) => void;
}

const TextColor = '#3c8dbc!important';
const useStyles = makeStyles({
  action: { color: TextColor, paddingRight: '30px!important' },
  actionButon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  table: {
    border: '2px solid #3c8dbc',
    boxShadow: 'none!important',
  },
  header: {
    background: '#ECF3FB',
    borderBottom: '2px solid  #3c8dbc!important',
  },
  textHeader: {
    color: TextColor,
  },
  textHeaderCreate: {
    color: TextColor,
  },
  textHeaderBug: {
    color: TextColor,
  },
  textHeaderStatus: {
    color: TextColor,
  },
  edit: {
    fontSize: '1.2rem!important',
  },
  delete: {
    fontSize: '1.2rem!important',
  },
  status_pending: {
    backgroundColor: '#F0AD4E',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_fail: {
    backgroundColor: '#D9534F',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_success: {
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#5CB85C',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
  },
  Nodata: {
    color: '#DAE0E5',
  },
});

export default function FileManagementTable({ data, onErr, onViewData }: FileManagementTableProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<FileManagement>();

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (form: FileManagement) => {
    setOpen(true);
    setSelected(form);
  };
  const DATE_OPTIONS = { year: 'numeric', month: 'numeric', day: 'numeric', hours: 'numeric' };
  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.textHeader}>{t('stt')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Name File')}</TableCell>
              <TableCell className={classes.textHeaderCreate}>{t('Creation date')}</TableCell>
              <TableCell className={classes.textHeader}>{t('File deletion date')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Process')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Status')}</TableCell>
              <TableCell className={classes.textHeaderBug}>{t('Error')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Data')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((e, index) => (
                <TableRow key={e.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{e.fileName}</TableCell>
                  <TableCell>{moment(e.createdDate).format('DD/MM/YYYY - h:mm:ss a')}</TableCell>
                  <TableCell>{moment(e.deletedDate).format('DD/MM/YYYY - h:mm:ss a')}</TableCell>
                  <TableCell>
                    {e.fileImportStatus !== 'Succeeded' ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      t('Success')
                    )}
                  </TableCell>
                  <TableCell>
                    {e.fileDataStatus === 0 ? (
                      <span className={classes.status_pending}>{t('Processing')}</span>
                    ) : e.fileDataStatus === 1 ? (
                      <span className={classes.status_fail}>{t('Invalid')}</span>
                    ) : (
                      <span className={classes.status_success}>{t('Valid')}</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {!e.inValidMessage ? (
                      ''
                    ) : (
                      <Tooltip title={t('Error').toString()}>
                        <IconButton
                          aria-label="error"
                          color="primary"
                          sx={{ color: red[500] }}
                          onClick={() => handleClick(e)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t('Data').toString()}>
                      <IconButton aria-label="data" color="primary" onClick={() => onViewData?.(e)}>
                        <StorageIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={16}>
                  {t('No data to display')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FormBug
        deletez={`${t('Bug')}`}
        open={open}
        onClose={handleClose}
        item={selected}
        mainMessage={`${t('Bug :')} ${
          (console.log('s', selected?.inValidMessage),
          selected?.inValidMessage! == 'Column ngàysinh(dd/mm/yyyy)(*) is not existed'
            ? '"Ngày sinh không tồn tại"'
            : selected?.inValidMessage! == 'Column họtên(*) is not existed'
            ? 'Họ tên không tồn tại'
            : '')
        }`}
      />
    </>
  );
}
