import * as React from 'react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { viVN } from '@mui/material/locale';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
// import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useEffect, useState, DragEvent, ChangeEvent } from 'react';
import Popup from 'components/Common/Popup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Paginations from 'components/Common/Pagination';
import { FileManagement, ParamsFileManagement } from '../models/fileManagement';
import {
  fileManagementActions,
  selecFileManagementFilter,
  selectFileManagement,
  selectFileManagementLoading,
  selectFileManagementTotalRows,
} from '../fileManagementSlice';
import FilterFileManagement from '../components/FilterFileManagement';
import FileManagementTable from '../components/FileManagementTable';
import TestingForm from 'features/importexam/components/FormImport';
import { useHistory } from 'react-router';
import { FileUpload, UploadFileStatus } from 'components/Common/FileUpload';
import { stringUtils } from 'utils';
import LinearProgressWithLabel from 'components/Common/LinearProgressWithLabel';
import { fileManagementApi } from '../api/fileManagementAPI';
import { EXCEL_FILE_BASE64 } from '../utils/constans';
import FileSaver from 'file-saver';
import NewFilters from '../components/NewFilter';
import { ButtonEffect } from 'components/Common/ButtonEffect';
import DividerText from '../components/DownloadTemplateComponent';
const theme = createTheme({}, viVN);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    titleContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',

      marginBottom: 16,
    },

    loading: {
      position: 'absolute',
      top: -8,
      width: '100%',
    },
    filter: {
      width: '100%',
      display: 'flex',
      justifyItems: 'space-between',
      alignItems: 'center',
    },
  })
);

export const ListPage = () => {
  const [fileManagement, setFileManagement] = useState<FileManagement>();
  const [getFileManagement, setGetFileManagement] = useState<FileManagement[]>();
  // const initialValues: FileManagement = {
  //   fileName: '',
  //   createdDate: '',
  //   updatedDate: '',
  //   fileImportStatus: '',
  //   fileDataStatus: 1,
  //   ...fileManagement,
  // } as FileManagement;

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupBug, setOpenPopupBug] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [onUpload, setOnUpload] = useState<boolean>();
  const [doneUpload, setDoneUpload] = useState<boolean>();
  const [progress, setProgress] = useState<number>();
  const [uploadFiles, setUploadFiles] = useState<UploadFileStatus[]>();

  const loading = useAppSelector(selectFileManagementLoading);

  const data = useAppSelector(selectFileManagement);
  const totalRows = useAppSelector(selectFileManagementTotalRows);
  const filter = useAppSelector(selecFileManagementFilter);
  const history = useHistory();
  // React.useCallback(() => {
  //   dispatch(techSkillActions.getItems(filter));
  // }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fileManagementActions.getItems(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    setGetFileManagement(getFileManagement);
  }, [getFileManagement]);

  useEffect(() => {
    const checkOnUpload = () => {
      return (
        uploadFiles && uploadFiles.length > 0 && uploadFiles.filter((_) => _.status).length > 0
      );
    };

    const checkDoneUpload = () => {
      return (
        uploadFiles &&
        uploadFiles.length > 0 &&
        uploadFiles.filter((_) => _.status === 2 || _.status === 3).length === uploadFiles.length
      );
    };

    const calcProgress = () => {
      if (uploadFiles && uploadFiles.length > 0) {
        let done = uploadFiles.filter((_) => _.status === 2 || _.status === 3).length;
        // let total = uploadFiles.length;
        return done;
      }
      return 0;
    };
    setOnUpload(checkOnUpload());
    setDoneUpload(checkDoneUpload());
    setProgress(calcProgress());
  }, [uploadFiles]);

  //pagination
  const handlePageChange = (e: any, page: number) => {
    setPageIndex(page);
    dispatch(
      fileManagementActions.setFilter({
        ...filter,
        PageIndex: page,
      })
    );
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      fileManagementActions.setFilter({
        ...filter,
        PageIndex: 0,
        PageSize: parseInt(e.target.value, 10),
      })
    );
  };
  // upload file
  const handleFileChange = (files: FileList) => {
    let items: UploadFileStatus[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      items.push({
        succeed: undefined,
        file: file,
        index: index,
      } as UploadFileStatus);
    }
    setUploadFiles(items);
  };
  const handleUploadStatus = (index: number, status: number) => {
    console.log(index, status);
    if (uploadFiles) {
      let clone = [...uploadFiles];
      clone[index].status = status;
      setUploadFiles(clone);
    }
  };
  const handleCloseDrawer = () => {
    if (onUpload) {
      if (doneUpload) {
        setOpenDrawer(false);
        setUploadFiles(undefined);
      }
      return;
    }

    setOpenDrawer(false);
    setUploadFiles(undefined);
  };
  // ----------------------------------------
  // download file
  const handleDownload = () => {
    let dataBlob = EXCEL_FILE_BASE64;
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    let blob = new Blob(byteArrays, { type: 'application/vnd.ms-excel' });
    FileSaver.saveAs(new Blob([blob], {}), 'Mau_HoTen.xlsx');
  };
  // edit
  const handleBug = () => {
    console.log('ok');
    setOpenPopupBug(true);
  };
  // file management - get by id
  const handleGetId = (fileManagement: FileManagement) => {
    history.push(`/file-management/${fileManagement.id}`);
  };

  //filter
  const handleSearchChange = (newFilter: ParamsFileManagement) => {
    dispatch(fileManagementActions.setFilter(newFilter));
  };
  const handleFilterChange = (newFilter: ParamsFileManagement) => {
    dispatch(fileManagementActions.setFilter(newFilter));
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typography component="h1" variant="h5" fontWeight="bold">
            {t('File Management')}
          </Typography>
        </Box>
        <Grid container mb={3} display="flex" flexDirection="row" justifyContent="space-between">
          {/* filter  */}
          <Grid xs={6} width="100%" md={6}>
            <FilterFileManagement
              filter={filter}
              onSearchChange={handleSearchChange}
              onChange={handleFilterChange}
            />
            {/* <NewFilters filter={filter} /> */}
          </Grid>
          {/* import  */}
          <Grid
            width="100%"
            xs={4}
            md={4}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            margin="15px 25px 0 0 "
          >
            <Tooltip title={t('Import Excel').toString()}>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* Table */}
        {loading && <LinearProgress />}
        <FileManagementTable data={data || []} onErr={handleBug} onViewData={handleGetId} />

        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <Paginations
            count={Math.ceil(totalRows / filter.PageSize)}
            page={filter.PageIndex}
            rowsPerPage={filter.PageSize}
            PageIndexs={filter.PageIndex}
            totalCount={totalRows}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onChange={(e: any, page: number) => {
              handlePageChange(e, page - 1);
            }}
            onPageChange={(e: any, page: number) => {
              handlePageChange(e, page);
            }}
          />
        </Box>
      </div>
      <Popup
        title={`${t('Import')}`}
        openPopup={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setFileManagement(undefined);
        }}
      >
        <TestingForm
          onClose={() => {
            setOpenPopup(false);
            setFileManagement(undefined);
          }}
        />
      </Popup>
      <Popup
        title={`${t('Bug')}`}
        openPopup={openPopupBug}
        onClose={() => {
          setOpenPopupBug(false);
          setFileManagement(undefined);
        }}
      >
        bug
      </Popup>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          handleCloseDrawer();
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'space-between',
            justifyContent: 'space-between',
            height: '100%',
            width: '450px',
          }}
        >
          <div>
            <div
              style={{
                height: '30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <Typography></Typography>
              <IconButton
                onClick={() => {
                  handleCloseDrawer();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DividerText />
            <div style={{ display: 'flex', justifyContent: 'center', margin:'20px' }}>
              <Button
                variant="outlined"
                startIcon={<DocumentScannerIcon />}
                onClick={handleDownload}
              >
                {/* <a href="/excel/NNC_Test2.xlsx" download style={{ textDecoration: 'none' }}> */}
                {t('template file')}
                {/* </a> */}
              </Button>
            </div>

            <Divider />
            <Typography
              variant="h6"
              style={{
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              {onUpload ? '' : t('Select a file to import')}
            </Typography>
            {!onUpload && (
              <div
                style={{
                  border: '1px solid #e2e6f0',
                  height: '152px',
                  margin: '15px',
                  backgroundColor: '#fbfcfd',
                }}
              >
                <FileUpload
                  width="420px"
                  height="150px"
                  hoverLabel={t('Click or drag to upload file').toString()}
                  dropLabel={t('Drop file here').toString()}
                  accept={
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  }
                  multiple={true}
                  onChange={function (event: React.ChangeEvent<HTMLInputElement>): void {
                    if (event.target.files !== null && event.target?.files?.length > 0) {
                      handleFileChange(event.target.files);
                    }
                  }}
                  onDrop={function (event: DragEvent<HTMLElement>): void {
                    handleFileChange(event.dataTransfer.files);
                  }}
                />
              </div>
            )}
            {uploadFiles && (
              /*
                  List of file
                 */
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  margin: '15px',
                  textAlign: 'center',
                }}
              >
                {onUpload && (
                  <div
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <LinearProgressWithLabel value={progress ?? 0} total={uploadFiles.length} />
                  </div>
                )}
                <List
                  sx={{
                    width: '100%',
                    maxHeight: onUpload ? 'calc(100vh - 190px)' : 'calc(100vh - 380px)',
                    overflow: 'auto',
                  }}
                >
                  {uploadFiles.map((item) => (
                    <ListItem
                      key={item.file.name}
                      secondaryAction={
                        item.status === 1 ? (
                          <CircularProgress size={15} />
                        ) : item.status === 2 ? (
                          <CheckCircleIcon color="success" />
                        ) : item.status === 3 ? (
                          <ErrorIcon color="error" />
                        ) : (
                          ''
                        )
                      }
                      style={{
                        backgroundColor:
                          item.status === 2 ? '#eaf6f0' : item.status === 3 ? '#fbe8e8' : '',
                      }}
                    >
                      <ListItemAvatar>
                        <InsertDriveFileIcon
                          color={
                            item.status === 2 ? 'success' : item.status === 3 ? 'error' : 'inherit'
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText primary={stringUtils.getName(item.file.name, 38)} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </div>
          <div
            style={{
              height: '80px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f4f7fa',
            }}
          >
            {onUpload && (
              <>
                <Button
                  style={{
                    marginLeft: '25px',
                  }}
                  onClick={() => {
                    handleCloseDrawer();
                  }}
                  variant="contained"
                  disabled={!doneUpload}
                >
                  {t('done')}
                </Button>
              </>
            )}
            {!onUpload && (
              <>
                <Button
                  style={{
                    marginLeft: '25px',
                  }}
                  onClick={() => {
                    handleCloseDrawer();
                  }}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  style={{
                    marginRight: '25px',
                  }}
                  disabled={!uploadFiles}
                  variant="contained"
                  onClick={async () => {
                    if (uploadFiles) {
                      for (let index = 0; index < uploadFiles.length; index++) {
                        const item = uploadFiles[index];
                        try {
                          handleUploadStatus(index, 1);
                          const response = await fileManagementApi.import(item.file);
                          if (response.succeed) {
                            handleUploadStatus(index, 2);
                          } else {
                            handleUploadStatus(index, 3);
                          }
                        } catch (error) {
                          handleUploadStatus(index, 3);
                        }
                        setUploadFiles(uploadFiles);
                      }
                    }
                    handleFilterChange({ ...filter });
                  }}
                >
                  {t('Upload')}
                </Button>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  );
};
