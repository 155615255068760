import { Alert, Box, Button, Collapse, Grid, Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Field } from 'components/Field';
import { t, use } from 'i18next';
import { DoHParams, LocationsValue } from '../models/doh';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Local } from './Local';
import { ListParams } from 'models';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
export interface Props {
  filter: DoHParams;
  onChange?: (newFilter: ListParams) => void;
  onSearchChange?: (newFilter: DoHParams) => void;
}

const useStyles = makeStyles({
  cointainer: {
    overflow: 'hidden!important',
  },
  GridSelect: {
    marginLeft: '60px',
  },
  Field: {
    overflow: 'hidden',
  },
});
export default function FilterDoH({ filter, onChange, onSearchChange }: Props) {
  const searchRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const [local, setCocal] = useState<LocationsValue>();
  const [cccd, setCccd] = useState<string>('');
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const handleSearchChange1 = (_local: LocationsValue) => {
    var fil = {
      District: _local?.District,
      CCCD: cccd,
      PageIndex: filter.PageIndex,
      PageSize: filter.PageSize,
    } as ListParams;
    onChange?.(fil);
  };
  useEffect(() => {}, [dispatch]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onSearchChange) return;
    setCccd(e.target.value);
    const newFilter: DoHParams = {
      ...filter,
      CCCD: e.target.value,
      // District: e.target.value,
    };
    onSearchChange(newFilter);
  };
  const handleClearFilter = () => {
    if (!onChange) return;

    const newFilter: DoHParams = {
      ...filter,
      CCCD: undefined,
      PageIndex: 0,
      // District: undefined,
    };
    onChange(newFilter);

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };
  return (
    <Box sx={{ width: '70%' }}>
      <Collapse in={open}>
        <Alert
          icon={false}
          action={
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, minWidth: '500px' }}
        >
          <Grid container spacing={3} className={classes.cointainer}>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden!important',
                minWidth: '450px!important',
              }}
            >
              <Field className={classes.Field}>
                {/* <Label>{t('Find')}</Label>
            <Input onChange={handleSearchChange} endAdornment={<Search />} inputRef={searchRef} /> */}
                <Paper
                  component="form"
                  sx={{
                    ml: '20px',
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 400,
                    borderShadow: 'none!important',
                    overflow: 'hidden!important',
                  }}
                >
                  {' '}
                  <InputBase
                    sx={{ ml: 1, flex: 1, borderShadow: 'none!important' }}
                    placeholder={t('Find Citizenship ID')}
                    inputRef={searchRef}
                    onChange={handleSearchChange}
                  />
                  <SearchIcon sx={{ m: '10px' }} />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Tooltip title={t('Clean').toString()}>
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                      <RefreshIcon onClick={handleClearFilter} />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </Field>
            </Grid>
            <Grid item md={4} className={classes.GridSelect}>
              <Paper
                component="form"
                sx={{
                  mt: '15px',
                  p: '2px 4px',
                  display: 'flex',
                  height: 50,
                  alignItems: 'center',
                  width: 260,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
              >
                <Local
                  onChange={(e) => {
                    console.log(e);
                    handleSearchChange1(e);
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Alert>
      </Collapse>
      {open == false && (
        <Tooltip title={t('Filter').toString()}>
          <Button
            disabled={open}
            variant="outlined"
            onClick={() => {
              setOpen(true);
            }}
          >
            <FilterAltIcon />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
}
