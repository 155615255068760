import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { t } from 'i18next';
import { SelectField } from 'components/FormFields';
import { useForm } from 'react-hook-form';
import { SelectOption } from '../../../components/FormFields/SelectField';
import { useAppDispatch } from 'app/hooks';
import LocalAPI from '../../../app/assets/locations.json';
import { District, LocationsValue, Province, Quarter, SubQuarter, Ward } from '../models/doh';
import { log } from 'console';
import { makeStyles } from '@mui/styles';
export interface Props {
  initialValues?: LocationsValue;
  onChange?: (newFilter: LocationsValue) => void;
  onSearchChange?: (newFilter: LocationsValue) => void;
  reset?: (newFilter: LocationsValue) => void;
}

const useStyles = makeStyles({
  Box: {
    backgroundColor: 'transparent ',
  },
});
export const Local = ({ initialValues, onChange, onSearchChange, reset }: Props) => {
  const searchRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  //
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<LocationsValue>({
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (formValues: any) => {};

  const [iniValues, setInitialValues] = React.useState(initialValues);
  const [province, setProvince] = React.useState<Province>();
  const [district, setDistrict] = React.useState<District>();
  const [ward, setWard] = React.useState<Ward>();
  const [quarter, setQuarter] = React.useState<Quarter>();
  const [subQuarter, setSubQuarter] = React.useState<SubQuarter>();

  const _provinceCode = watch('ProvinceCode');
  const _District = watch('District');
  // const _WardCode = watch('WardCode');
  // const _QuarterCode = watch('QuarterCode');
  // const _SubQuarterCode = watch('SubQuarterCode');

  const onChangeHandler = () => {
    var local = {
      // ProvinceCode: _provinceCode,
      District: _District,
      // WardCode: _WardCode,
      // QuarterCode: _QuarterCode,
      // SubQuarterCode: _SubQuarterCode,
    } as unknown as LocationsValue;
    console.log('🚀 ~ file: Local.tsx:65 ~ onChangeHandler ~ _District:', _District);
    onChange?.(local);
  };

  // useEffect(() => {
  //   if (iniValues) {
  //     console.log(iniValues);
  //     setValue('ProvinceCode', iniValues?.ProvinceCode);
  //     setValue('DistrictCode', iniValues?.DistrictCode);
  //     setValue('WardCode', iniValues?.WardCode);
  //     setValue('QuarterCode', iniValues?.QuarterCode);
  //     setValue('SubQuarterCode', iniValues?.SubQuarterCode);
  //   }
  // }, [iniValues]);

  useEffect(() => {
    // string
    if (_provinceCode) {
      var pro = LocalAPI.filter((e) => {
        if (e.Value == _provinceCode) {
          return e;
        }
      });
      setProvince(pro[0]);
      setValue('District', undefined);
      // setValue('WardCode', '');
      // setValue('QuarterCode', '');
      // setValue('SubQuarterCode', '');
      // onChangeHandler();
    }
  }, [_provinceCode]);

  useEffect(() => {
    // string
    if (province) {
      var pro = province.Districts.filter((e) => {
        if (e.Value == _District) {
          return e;
        }
      });
      //  setDistrict(pro[0]);
      // setValue('WardCode', '');
      // setValue('QuarterCode', '');
      // setValue('SubQuarterCode', '');
      onChangeHandler();
    }
  }, [_District, setDistrict]);

  // useEffect(() => {
  //   // stringyuoioiv
  //   if (district) {
  //     var pro = district.Wards.filter((e) => {
  //       if (e.Value == _WardCode) {
  //         return e;
  //       }
  //     });
  //     setWard(pro[0]);
  //     setValue('QuarterCode', '');
  //     setValue('SubQuarterCode', '');
  //     onChangeHandler();
  //   }
  // }, [_WardCode]);

  // useEffect(() => {
  //   // string
  //   if (ward) {
  //     var pro = ward?.Quarters.filter((e) => {
  //       if (e.Label == _QuarterCode) {
  //         return e;
  //       }
  //     });
  //     setQuarter(pro[0] || undefined);
  //     setValue('SubQuarterCode', '');
  //     onChangeHandler();
  //   }
  // }, [_QuarterCode]);

  // useEffect(() => {
  //   // string
  //   if (quarter) {
  //     var pro = quarter?.SubQuarters.filter((e) => {
  //       if (e.Label == _SubQuarterCode) {
  //         return e;
  //       }
  //     });
  //     setSubQuarter(pro[0] || undefined);
  //     onChangeHandler();
  //   }
  // }, [_SubQuarterCode]);
  return (
    <Box className={classes.Box}>
      <Grid container spacing={1} sx={{ width: '517px!important' }}>
        <Grid item md={3}>
          <SelectField
            options={LocalAPI.map((e) => ({ label: e.Label, value: e.Value } as SelectOption))}
            label={t('Province')}
            control={control}
            name="ProvinceCode"
            style={{ backgroundColor: '#fff' }}
          />
        </Grid>
        <Grid item md={3}>
          <SelectField
            style={{ backgroundColor: '#fff' }}
            options={
              province?.Districts.map(
                (e) => ({ label: e.Label, value: e.Label } as SelectOption)
              ) || []
            }
            label={t('District')}
            control={control}
            name="District"
          />
        </Grid>{' '}
        {/* <Grid item md={3}>
          <SelectField
            options={
              district?.Wards.map((e) => ({ label: e.Label, value: e.Value } as SelectOption)) || []
            }
            label={t('Ward')}
            control={control}
            name="WardCode"
          />
        </Grid>{' '} */}
        {/* <Grid item md={3}>
          <SelectField
            options={
              ward?.Quarters.map((e) => ({ label: e.Label, value: e.Label } as SelectOption)) || []
            }
            label="QuarterCode"
            control={control}
            name="QuarterCode"
          />
        </Grid>{' '}
        <Grid item md={3}>
          <SelectField
            options={
              quarter?.SubQuarters.map(
                (e) => ({ label: e.Label, value: e.Label } as SelectOption)
              ) || []
            }
            label="SubQuarterCode"
            control={control}
            name="SubQuarterCode"
          />
        </Grid>{' '} */}
      </Grid>
    </Box>
  );
};
