import { ListResponse } from 'models';
import { apiLinks } from 'utils';
import axiosClient from '../../../api/axiosClient';
import { DoH, DoHParams } from '../models/doh';

export const DoHApi = {
  get(params: DoHParams): Promise<ListResponse<DoH>> {
    return axiosClient.get(apiLinks.dataReconciliation.doh, { params });
  },
};
