import * as React from 'react';

export interface IPageNotFoundProps {
}

export function PageNotFound (props: IPageNotFoundProps) {
  return (
    <div>
      Not found
    </div>
  );
}
