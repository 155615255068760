import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { DataManagement } from '../models/dataManagement';
import { red } from '@mui/material/colors';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import FormBug from 'features/fileManagement/components/FormBug';

export interface DataManagementTableProps {
  data: DataManagement[];
  onErr?: (dataManagement: DataManagement) => void;
}
const TextColor = '#3c8dbc!important';
const useStyles = makeStyles({
  action: { color: TextColor, paddingRight: '30px!important' },
  actionButon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  table: {
    border: '2px solid #3c8dbc',
    boxShadow: 'none!important',
  },
  header: {
    background: '#ECF3FB',
    borderBottom: '2px solid  #3c8dbc!important',
  },
  textHeader: {
    color: TextColor,
  },
  status_pending: {
    backgroundColor: '#F0AD4E',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_fail: {
    backgroundColor: '#D9534F',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  status_success: {
    minWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#5CB85C',
    padding: '0.3em',
    borderRadius: '5px',
    color: '#fff',
  },
  edit: {
    fontSize: '1.2rem!important',
  },
  delete: {
    fontSize: '1.2rem!important',
  },
  Nodata: {
    color: '#DAE0E5',
  },
  textHeaderAddress: {
    textAlign: 'center',
    color: '#fff',
  },
});

export default function DataManagementTable({ data, onErr }: DataManagementTableProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<DataManagement>();

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (form: DataManagement) => {
    setOpen(true);
    setSelected(form);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small" aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.textHeader}>{t('stt')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Fullname')}</TableCell>
              <TableCell className={classes.textHeader}>{t('BOD')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Gender')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Identity card')}</TableCell>
              <TableCell className={classes.textHeaderAddress}>{t('Address')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Status')}</TableCell>
              <TableCell className={classes.textHeader}>{t('Error')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((e, index) => (
                <TableRow key={e.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {!e.riskGroupDataSource?.fullName ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      e.riskGroupDataSource?.fullName
                    )}
                  </TableCell>
                  <TableCell>
                    {e.riskGroupDataSource === null ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      `${
                        e.riskGroupDataSource?.dob === 'Invalid date' ? (
                          <span className={classes.Nodata}>{t('No data')}</span>
                        ) : (
                          e.riskGroupDataSource?.dob
                        )
                      }`
                    )}
                  </TableCell>
                  <TableCell>
                    {!e.riskGroupDataSource?.gender ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      e.riskGroupDataSource?.gender
                    )}
                  </TableCell>
                  <TableCell>
                    {!e.riskGroupDataSource?.cccd ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      e.riskGroupDataSource?.cccd
                    )}
                  </TableCell>
                  <TableCell>
                    {e.riskGroupDataSource === null ? (
                      <span className={classes.Nodata}>{t('No data')}</span>
                    ) : (
                      `${`${e.riskGroupDataSource?.houseNumber}, ${e.riskGroupDataSource.ward}, ${e.riskGroupDataSource.district}, ${e.riskGroupDataSource.city}`}`
                    )}
                  </TableCell>
                  <TableCell>
                    {e.riskGroupDataSource?.fileDataStatus === 0 ? (
                      <span className={classes.status_pending}>{t('Processing')}</span>
                    ) : e.fileDataStatus === 1 ? (
                      <span className={classes.status_fail}>{t('Invalid')}</span>
                    ) : (
                      <span className={classes.status_success}>{t('Valid')}</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {!e.inValidMessage ? (
                      ''
                    ) : (
                      <Tooltip title={t('Error').toString()}>
                        <InfoIcon
                          sx={{ color: red[500], cursor: 'pointer' }}
                          onClick={() => handleClick(e)}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={16}>
                  {t('No data to display')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FormBug
        deletez={`${t('Bug')}`}
        open={open}
        onClose={handleClose}
        item={selected}
        mainMessage={`${t('Bug :')} ${
          (console.log('s', selected?.inValidMessage),
          !selected?.inValidMessage ? '' : selected?.inValidMessage)
        }`}
      />
    </>
  );
}
