import * as React from 'react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { viVN } from '@mui/material/locale';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Popup from 'components/Common/Popup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Paginations from 'components/Common/Pagination';
import { DoH, DoHParams } from '../models/doh';
import {
  DoHActions,
  selectDoH,
  selectDoHFilter,
  selectDoHFilterDOH,
  selectDoHLoading,
  selectDoHTotalRows,
} from '../dohSlice';
import DoHTable from '../components/DoHTable';
import FilterDoH from '../components/DoHFilter';

const theme = createTheme({}, viVN);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    titleContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',

      marginBottom: 16,
    },

    loading: {
      position: 'absolute',
      top: -8,
      width: '100%',
    },
    filter: {
      width: '100%',
      display: 'flex',
      justifyItems: 'space-between',
      alignItems: 'center',
    },
  })
);

export const ListPage = () => {
  const [DoH, setDoH] = useState<DoH>();
  const [getDoH, setGetDoH] = useState<DoH[]>();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPut, setOpenPopupPut] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const loading = useAppSelector(selectDoHLoading);

  const data = useAppSelector(selectDoH);
  const filter = useAppSelector(selectDoHFilter);
  const filterDoH = useAppSelector(selectDoHFilterDOH);

  const totalRows = useAppSelector(selectDoHTotalRows);

  // React.useCallback(() => {
  //   dispatch(techSkillActions.getItems(filter));
  // }, [dispatch, filter]);

  useEffect(() => {
    dispatch(DoHActions.getItems(filterDoH));
  }, [dispatch, filterDoH]);

  useEffect(() => {
    setGetDoH(getDoH);
  }, [setGetDoH]);

  //pagination
  const handlePageChange = (e: any, page: number) => {
    setPageIndex(page);
    dispatch(
      DoHActions.setFilterDM({
        ...filterDoH,
        PageIndex: page,
      })
    );
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      DoHActions.setFilterDM({
        ...filterDoH,
        PageIndex: 0,
        PageSize: parseInt(e.target.value, 10),
      })
    );
  };
  // ----------------------------------------

  // erorr
  const handle = () => {
    console.log('ok');
    setOpenPopup(true);
  };

  //filter
  const handleSearchChange = (newFilter: DoHParams) => {
    dispatch(DoHActions.setFilterDM(newFilter));
  };
  const handleFilterChange = (newFilter: DoHParams) => {
    dispatch(DoHActions.setFilterDM(newFilter));
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typography component="h1" variant="h5" fontWeight="bold">
            {t('DoH')}
          </Typography>
        </Box>
        {/* filter */}
        <Grid container mb={3} display="flex" flexDirection="row" justifyContent="space-between">
          <Grid xs={6} width="70%" md={6}>
            <FilterDoH
              filter={filterDoH}
              onChange={handleSearchChange}
              onSearchChange={handleFilterChange}
            />
          </Grid>
        </Grid>
        <Grid xs={6} width="50%" md={6} sx={{ marginBottom: '20px' }}></Grid> {/* Table */}
        {loading && <LinearProgress />}
        <DoHTable data={data || []} />
        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <Paginations
            count={Math.ceil(totalRows / filter.PageSize)}
            page={filter.PageIndex}
            rowsPerPage={filterDoH.PageSize}
            PageIndexs={filterDoH.PageIndex}
            totalCount={totalRows}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onChange={(e: any, page: number) => {
              handlePageChange(e, page - 1);
            }}
            onPageChange={(e: any, page: number) => {
              handlePageChange(e, page);
            }}
          />
        </Box>
      </div>
    </ThemeProvider>
  );
};
