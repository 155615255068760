import { PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { ListParams, ListResponse, PaginationParams } from 'models';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { dataManagementApi } from './api/dataManagementAPI';
import { dataManagementActions } from './dataManagementSlice';
import {
  DataManagement,
  ListResponseDataManagement,
  ParamsDataManagement,
} from './models/dataManagement';

// get list
function* fetchItemsDataManagement(action: PayloadAction<ParamsDataManagement>) {
  try {
    const res: ListResponse<DataManagement> = yield call(
      dataManagementApi.get,
      action.payload
    );
    yield put(dataManagementActions.getItemsSuccess(res));
  } catch (error: any) {
    console.log(error);
    yield put(dataManagementActions.getItemsFailed());
  }
}
function* getItems() {
  yield takeEvery(dataManagementActions.getItems, fetchItemsDataManagement);
}

// khi có sự kiện thì gọi
export function* dataManagementSaga() {
  yield all([fork(getItems)]);
}
