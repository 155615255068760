import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ListParams, ListResponse } from 'models';
import {
  DataManagement,
  ListParamsDM,
  ListResponseDataManagement,
  ParamsDataManagement,
} from './models/dataManagement';

export interface DataManagementModel {
  isLoading: Boolean;
  items?: DataManagement[];
  error?: string;
  filterDM: ParamsDataManagement;
  filterDMStatus: ParamsDataManagement;
  filter: ListParams;
  totalPages: number;
  totalRows: number;
}
const initialState: DataManagementModel = {
  isLoading: false,
  items: [],
  error: '',
  filter: {
    PageIndex: 0,
    PageSize: 10,
  },
  filterDMStatus: {
    FileManagementId: undefined,
    FileDataStatus: undefined,
    PageIndex: 0,
    PageSize: 10,
  },
  filterDM: {
    FileManagementId: undefined,
    FileDataStatus: undefined,
    PageIndex: 0,
    PageSize: 10,
  },
  totalPages: 0,
  totalRows: 0,
};

const dataManagementSlice = createSlice({
  name: 'data management',
  initialState,
  reducers: {
    // get data
    getItems(state, action: PayloadAction<ParamsDataManagement>) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action: PayloadAction<ListResponse<DataManagement>>) {
      state.items = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.totalRows = action.payload.totalRows;
      state.isLoading = false;
    },
    getItemsFailed(state) {
      state.isLoading = false;
      state.error = 'err';
    },
    setFilter(state, action: PayloadAction<ListParamsDM>) {
      state.filter = {
        ...action.payload,
        PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
      };
    },
    setFilterDM(state, action: PayloadAction<ParamsDataManagement>) {
      state.filterDM = {
        ...action.payload,
        // PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        FileManagementId: action.payload.FileManagementId,
        FileDataStatus: action.payload.FileDataStatus,
        PageSize:action.payload.PageSize,
        PageIndex: action.payload.PageIndex,
      };
    },
    setFilterDMStatus(state, action: PayloadAction<ParamsDataManagement>) {
      state.filterDMStatus = {
        ...action.payload,
        // PageIndex: action.payload.PageIndex > 0 ? action.payload.PageIndex : 0,
        FileManagementId: action.payload.FileManagementId,
        FileDataStatus: action.payload.FileDataStatus,
        PageSize: 10,
        PageIndex: 0,
      };
    },
  },
});

export const dataManagementActions = dataManagementSlice.actions;
export const selectDataManagement = (state: RootState) => state.dataManagement.items;
export const selectDataManagementLoading = (state: RootState) => state.dataManagement.isLoading;
export const selectDataManagementFilter = (state: RootState) => state.dataManagement.filter;
export const selectDataManagementFilterDM = (state: RootState) => state.dataManagement.filterDM;
export const selectDataManagementTotalRows = (state: RootState) => state.dataManagement.totalRows;

const dataManagementReducer = dataManagementSlice.reducer;
export default dataManagementReducer;
